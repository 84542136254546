import React from 'react'
import styled from 'styled-components'
import PointLayout from './pointLayout'
import { StaticImage } from 'gatsby-plugin-image'
import { ServiceBox } from 'components/group'
import { LeadBoxLarge } from 'components/atoms'
import { One, Two, Three } from 'components/icon/index'
import { LessonBox } from './group/lessonBox'
import {
  color,
  fontFamily,
  fixedHeaderHeight,
  innerWidth,
  rem,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface PointsSectionProps {
  readonly noProps?: string
}

type PointsSectionPropsClassName = PointsSectionProps & {
  readonly className: string
}

function Component({ className }: PointsSectionPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head-box">
        <LeadBoxLarge leadText={<>勉強って面白い！の</>} headingLevel="h2" />
      </div>
      <div className="e-lead">
        低学齢に合わせた
        <br />
        <span className="e-em">2つのカリキュラム</span>をご用意しました。
        <br />
        適切な授業時間とペースで、
        <br />
        先生たちがお子さまをリード。
      </div>
      <div className="e-point-box">算数｜英会話</div>
      <div className="e-items">
        <div className="e-item">
          <PointLayout
            head={{
              numberIcon: <One />,
              lead: 'ゲーム感覚で学べる',
              title: '算数',
            }}
            body={{
              image: <StaticImage src="../../../assets/images/kids/point1.jpg" alt="" />,
              lead: (
                <>
                  授業でポイントを正しく「理解」し、ゲーム感覚で楽しく「反復」練習をします。先生と一緒に話しながら授業が進むので、わからない問題をそのまま残すことなく学習できます。
                </>
              ),
              box: (
                <ServiceBox
                  head="サービス内容"
                  lists={[
                    ['通常レッスン', '週1回｜20分｜講師1人・生徒3人'],
                    ['選択レッスン', '月1回｜20分｜講師1人・生徒4人'],
                    ['成果伝達（年3回）'],
                    ['学力診断テスト（別料金）'],
                  ]}
                  headingLevel="h4"
                />
              ),
            }}
          />
        </div>
        <div className="e-item">
          <PointLayout
            head={{
              numberIcon: <Two />,
              lead: '英語って楽しい！を学べる',
              title: '英会話',
            }}
            body={{
              image: <StaticImage src="../../../assets/images/kids/point2.jpg" alt="" />,
              lead: (
                <>
                  明るく元気な講師と一緒に、ゲームや歌を通して「話す」「聞く」力をつけます。ゲーム感覚で学べるコンテンツ「クイックテスト」もご用意。小学校の外国語活動にむけて様々な単語や表現方法を学ぶことができます。
                </>
              ),
              box: (
                <ServiceBox
                  head="サービス内容"
                  lists={[
                    ['通常レッスン', '週1回｜20分｜講師1人・生徒3人'],
                    ['選択レッスン', '月1回｜20分｜講師1人・生徒4人'],
                    ['成果伝達（年3回）'],
                  ]}
                  headingLevel="h4"
                />
              ),
            }}
          />
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<PointsSectionPropsClassName>`
  padding-top: ${rem(200 / 2)};
  padding-bottom: ${rem(160 / 2)};

  > .e-head-box {
    text-align: center;
  }

  > .e-lead {
    margin-top: ${rem(80 / 2)};
    text-align: center;
    line-height: 2;

    > .e-em {
      color: ${color.main};
    }

    @media ${breakpoints.lg} {
      font-size: ${rem(20)};
    }
  }

  > .e-point-box {
    width: ${rem(550 / 2)};
    margin-top: ${rem(60 / 2)};
    margin-left: auto;
    margin-right: auto;
    padding-top: ${rem(40 / 2)};
    padding-bottom: ${rem(40 / 2)};
    border-top: solid 1px ${color.main};
    border-bottom: solid 1px ${color.main};
    font-family: ${fontFamily.zenKaku};
    text-align: center;
    font-size: ${rem(36 / 2)};
    font-weight: 500;
    color: ${color.main};
  }

  > .e-items {
    margin-top: ${rem(160 / 2)};

    > .e-item {
      &:nth-child(n + 2) {
        margin-top: ${rem(160 / 2)};
      }
    }
  }
`

export const PointsSection: (props: PointsSectionProps) => JSX.Element = StyledComponent

export default PointsSection
