import React from 'react'
import Layout from 'layout/base'
import Seo from 'components/seo'
import { TypeBreadcrumb } from 'types/breadcrumb'
import KidsCourseTemplate from 'templates/kidsCourseTemplate'
import { siteStructure } from 'utils/site'
import globalTitle from 'utils/globalTitle'

interface CourseKidsPageProps {
  pageContext: TypeBreadcrumb
  location: Location
}

const CourseKidsPage = ({ pageContext, location }: CourseKidsPageProps): JSX.Element => {
  const {
    breadcrumb: { crumbs },
  } = pageContext

  // TODO : 全ページで作っているので共通化したい
  const breadcrumbItems = crumbs
    .filter(crumb => {
      return crumb.crumbLabel !== 'course'
    })
    .map(crumb => {
      return crumb.crumbLabel in siteStructure
        ? {
            ...crumb,
            ...{ crumbLabel: siteStructure[crumb.crumbLabel]?.name },
          }
        : { ...crumb }
    })

  return (
    <Layout pageType="page" breadcrumbItems={breadcrumbItems} ctaType="type1">
      <Seo
        title={`こどもコース${globalTitle(location.pathname)}`}
        description="年長～小2のお子さまが対象。勉強って面白い！という好奇心を引き出し、集中できる楽しい授業を行います。"
        path={location.pathname}
      />
      <KidsCourseTemplate />
    </Layout>
  )
}

export default CourseKidsPage
