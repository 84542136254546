import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import HeadSection from 'components/section/course/headSection'
import PriceSection from 'components/section/course/priceSection'
import PointsSection from 'components/section/kids/pointsSection'
import StyleSection from 'components/section/styleSection'
import CallToActionSection from 'components/section/common/callToActionSection'
import { rem } from 'variables/_index'

export interface KidsCourseTemplateProps {
  readonly noProps?: string
}

type KidsCourseTemplatePropsClassName = KidsCourseTemplateProps & {
  readonly className: string
}

function Component({ className }: KidsCourseTemplatePropsClassName) {
  return (
    <div className={className}>
      <HeadSection
        imageElement={
          <StaticImage src="../assets/images/kids/mv.jpg" alt="" placeholder="none" />
        }
        title="こどもコース"
        year="年長～小２"
        leadElement={
          <>
            お子さまの好奇心を引き出し、
            <br />
            集中できる楽しい授業を行います。
          </>
        }
      />
      <PointsSection />
      <CallToActionSection contactId="cta_content1_trial" isDisplayDocument={false} />
      <PriceSection
        priceBoxes={[
          {
            headText: '英会話｜算数',
            bodyText1: <>1教科あたり　4,950円／月</>,
            leadText: '',
            subTexts: ['※ 算数の教材費 2,200円', '※ 諸経費 220円／月'],
          },
        ]}
        contentText="2コースを同時受講される場合は月謝割引があります。"
      />
      <StyleSection />
    </div>
  )
}

const StyledComponent = styled(Component)<KidsCourseTemplatePropsClassName>``

export const KidsCourseTemplate: (props: KidsCourseTemplateProps) => JSX.Element =
  StyledComponent

export default KidsCourseTemplate
