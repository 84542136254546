import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Rellax from 'rellax'
import { UnderBarHeading, ButtonSmall } from 'components/atoms/index'
import { PriceBox, PriceBoxProps } from 'components/group/index'
import {
  rem,
  innerWidth,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'
import { siteStructure } from 'utils/site'
export interface PriceSectionProps {
  readonly priceBoxes: Array<PriceBoxProps>
  readonly contentText?: string
  readonly isParallax?: boolean
}

type PriceSectionPropsClassName = PriceSectionProps & {
  readonly className: string
}

function Component({
  className,
  priceBoxes,
  contentText,
  isParallax,
}: PriceSectionPropsClassName) {
  const illustration16Ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (illustration16Ref.current) {
      new Rellax(illustration16Ref.current, {
        speed: 0.4, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-inner">
        <div className="e-head">
          <UnderBarHeading text="料金について" headingLevel="h2" />
        </div>
        <div className="e-price-items">
          {priceBoxes.map((priceBox, index) => (
            <div className="e-price-item" key={index}>
              <PriceBox
                headText={priceBox.headText}
                bodyText1={priceBox.bodyText1}
                leadText={priceBox.leadText}
                subTexts={priceBox.subTexts}
              />
            </div>
          ))}
        </div>
        {contentText && <div className="e-content">{contentText}</div>}
        <div className="e-button">
          <ButtonSmall href={siteStructure.fee.path} value="もっと詳しくみる" />
        </div>
        {isParallax && (
          <div ref={illustration16Ref} className="e-parallax">
            <StaticImage
              src="../../../assets/images/illustration/illustration16-2.png"
              alt=""
              placeholder="none"
            />
          </div>
        )}
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<PriceSectionPropsClassName>`
  padding-top: ${rem(200 / 2)};
  padding-bottom: ${rem(100 / 2)};

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: ${rem(40 / 2)};
    padding-right: ${rem(40 / 2)};
    position: relative;

    @media ${breakpoints.lg} {
      width: ${innerWidth.md};
      padding-left: ${rem(innerPadding.md + innerDummyPadding.md)};
      padding-right: ${rem(innerPadding.md + innerDummyPadding.md)};
    }

    > .e-head {
      text-align: center;
    }

    > .e-price-items {
      margin-top: ${rem(60 / 2)};

      > .e-price-item {
        &:nth-child(n + 2) {
          margin-top: ${rem(40 / 2)};
        }
      }
    }

    > .e-content {
      margin-top: ${rem(40 / 2)};
      line-height: 2;

      @media ${breakpoints.lg} {
        text-align: center;
      }
    }

    > .e-button {
      margin-top: ${rem(80 / 2)};
      text-align: center;
    }

    > .e-parallax {
      width: ${rem(65 / 2)};
      height: ${rem(36 / 2)};
      position: absolute;
      top: calc(100% + ${rem(60)});
      left: ${rem(40 / 2)};

      @media ${breakpoints.lg} {
        left: ${rem(innerPadding.md + innerDummyPadding.md)};
      }
    }
  }
`

export const PriceSection: (props: PriceSectionProps) => JSX.Element = StyledComponent

export default PriceSection
