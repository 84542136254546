import React from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import {
  innerWidth,
  rem,
  color,
  fontFamily,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'
import { ButtonExperience, ButtonAbout, ButtonDocument } from 'components/atoms/index'
import { siteStructure } from 'utils/site'

export interface CallToActionSectionProps {
  readonly isDisplayExperience?: boolean
  readonly contactId: string
  readonly isDisplayDocument?: boolean
  readonly isDisplayAbout?: boolean
  readonly isDisplayBox?: boolean
}

type CallToActionSectionPropsClassName = CallToActionSectionProps & {
  readonly className: string
}

function Component({
  className,
  isDisplayExperience = true,
  contactId,
  isDisplayDocument = true,
  isDisplayAbout = true,
  isDisplayBox = true,
}: CallToActionSectionPropsClassName) {
  const { ref, inView } = useInView({
    rootMargin: '-50px', // ref要素が現れてから50px過ぎたら
    triggerOnce: true, // 最初の一度だけ実行
  })

  return (
    <div className={className}>
      <div className="e-inner">
        <div ref={ref} className={inView ? 'e-heading is-in-view' : 'e-heading'}>
          成績アップ<span className="e-sm">の</span>
          <span className="e-em">
            秘<span className="e-point1"></span>
          </span>
          <span className="e-em">
            密<span className="e-point2"></span>
          </span>
          <span className="e-sm">を</span>体験！
        </div>
        {isDisplayBox && (
          <div className="e-points">
            <div className="e-point">
              最短翌日
              <div className="e-point-corner-top"></div>
              <div className="e-point-corner-bottom"></div>
            </div>
            <div className="e-point">
              スマホでもOK
              <div className="e-point-corner-top"></div>
              <div className="e-point-corner-bottom"></div>
            </div>
          </div>
        )}
        <div className="e-buttons">
          {isDisplayExperience && (
            <div className="e-button">
              <ButtonExperience id={contactId} href={siteStructure.experience1.path} />
            </div>
          )}
          {isDisplayDocument && (
            <div className="e-button">
              <ButtonDocument href={siteStructure.pamphlet1.path} />
            </div>
          )}
          {isDisplayAbout && (
            <div className="e-button">
              <ButtonAbout href={siteStructure.trial.path} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<CallToActionSectionPropsClassName>`
  @keyframes fadeUpPoint1 {
    0% {
      opacity: 0;
    }

    // 1つ目の点が表示されるタイミング
    30% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeUpPoint2 {
    0% {
      opacity: 0;
    }

    20% {
      opacity: 0;
    }

    // 2つ目の点が表示されるタイミング
    50% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes boundPoint1 {
    0% {
      transform: translate(-50%, 0px);
    }

    6% {
      transform: translate(-50%, -6px);
    }

    16% {
      transform: translate(-50%, 0);
    }

    100% {
      transform: translate(-50%, 0);
    }
  }

  @keyframes boundPoint2 {
    0% {
      transform: translate(-50%, 0);
    }

    16% {
      transform: translate(-50%, 0);
    }

    22% {
      transform: translate(-50%, -6px);
    }

    32% {
      transform: translate(-50%, 0);
    }

    100% {
      transform: translate(-50%, 0);
    }
  }

  padding-top: ${rem(100 / 2)};
  padding-bottom: ${rem(100 / 2)};
  background-color: ${color.accentCTA};

  @media ${breakpoints.lg} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }

    > .e-heading {
      font-family: ${fontFamily.zenKaku};
      font-size: 1.75rem;
      font-weight: 500;
      text-align: center;
      color: #fff;
      white-space: nowrap;

      @media ${breakpoints.lg} {
        font-size: ${rem(48)};
      }

      > .e-sm {
        font-size: 1.25rem;

        @media ${breakpoints.lg} {
          font-size: ${rem(33)};
        }
      }

      > .e-em {
        position: relative;

        > .e-point1,
        > .e-point2 {
          width: 0.344rem;
          height: 0.344rem;
          background-color: ${color.accent};
          border-radius: 50%;
          position: absolute;
          bottom: calc(100% + 0px);
          left: 50%;
          transform: translateX(-50%);

          @media ${breakpoints.lg} {
            width: ${rem(8)};
            height: ${rem(8)};
            bottom: calc(100% - ${rem(5)});
          }
        }
      }

      &.is-in-view {
        > .e-em {
          > .e-point1 {
            animation: boundPoint1 3s ease-in 3 forwards;
          }

          > .e-point2 {
            animation: boundPoint2 3s ease-in 3 forwards;
          }
        }
      }
    }

    > .e-points {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.953rem;

      @media ${breakpoints.lg} {
        margin-top: ${rem(20)};
      }

      .e-point {
        width: ${rem(284 / 2)};
        max-width: calc(50% - 1.844rem / 2);
        position: relative;
        padding: 0.8rem 0.2rem;
        font-family: ${fontFamily.zenKaku};
        font-size: 1.125rem;
        font-weight: 500;
        text-align: center;
        color: #fff;

        @media ${breakpoints.lg} {
          width: ${rem(226)};
          max-width: calc(50% - ${rem(48)} / 2);
          font-size: ${rem(22)};
        }

        &:nth-child(n + 2) {
          margin-left: 1.844rem;

          @media ${breakpoints.lg} {
            margin-left: ${rem(48)};
          }
        }

        > .e-point-corner-top {
          &::before,
          &::after {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border: solid 2px ${color.main};
            position: absolute;

            @media ${breakpoints.lg} {
              width: ${rem(18)};
              height: ${rem(18)};
            }
          }

          &::before {
            border-right: none;
            border-bottom: none;
            top: 0;
            left: 0;
          }

          &::after {
            border-left: none;
            border-bottom: none;
            top: 0;
            right: 0;
          }
        }

        > .e-point-corner-bottom {
          &::before,
          &::after {
            content: '';
            width: 0.75rem;
            height: 0.75rem;
            border: solid 2px ${color.main};
            position: absolute;

            @media ${breakpoints.lg} {
              width: ${rem(18)};
              height: ${rem(18)};
            }
          }

          &::before {
            border-right: none;
            border-top: none;
            bottom: 0;
            left: 0;
          }

          &::after {
            border-left: none;
            border-top: none;
            bottom: 0;
            right: 0;
          }
        }
      }
    }

    > .e-buttons {
      margin-top: ${rem(60 / 2)};

      @media ${breakpoints.lg} {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: ${rem(40)};
      }

      .e-button {
        text-align: center;

        @media ${breakpoints.lg} {
          max-width: calc(50% - ${rem(48)} / 2);
        }

        &:nth-child(n + 2) {
          margin-top: ${rem(40 / 2)};

          @media ${breakpoints.lg} {
            margin-top: 0;
            margin-left: ${rem(48)};
          }
        }

        &:nth-child(n + 3) {
          @media ${breakpoints.lg} {
            margin-top: ${rem(30)};
            margin-left: ${rem(0)};
          }
        }
      }
    }
  }
`

export const CallToActionSection: (props: CallToActionSectionProps) => JSX.Element =
  StyledComponent

export default CallToActionSection
