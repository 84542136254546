import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Rellax from 'rellax'
import { SectionHeadingTop, PageHead } from 'components/atoms/index'
import { StepBox, DeviceBox, RentalBox } from 'components/group/index'
import {
  breakpoints,
  innerWidth,
  rem,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface StyleSectionProps {
  readonly npProps?: string
}

type StyleSectionPropsClassName = StyleSectionProps & {
  readonly className: string
}

function Component({ className }: StyleSectionPropsClassName) {
  const illustration7Ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (illustration7Ref.current) {
      new Rellax(illustration7Ref.current, {
        speed: 0.8, // -10 ~ 10
        center: true,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-head">
        <div className="e-head-in">
          <SectionHeadingTop textElement={<>受講スタイル</>} headingLevel="h2" />
          <div ref={illustration7Ref} className="e-parallax">
            <StaticImage
              src="../../assets/images/illustration/illustration7-2.png"
              alt=""
              placeholder="none"
            />
          </div>
        </div>
      </div>
      <div className="e-style1">
        <div className="e-sub-head">
          <PageHead headingText="受講方法" headingLevel="h3" />
        </div>
        <div className="e-lead">
          受講はとても簡単！
          <br className="u-hidden-lg" />
          <span className="e-em">2ステップ</span>で
          <br className="u-hidden-lg" />
          授業を受けられます。
        </div>
        <div className="e-steps">
          <div className="e-step">
            <StepBox
              stepNumber={1}
              leadText="会員用ポータルサイトにアクセス"
              imageElement={
                <StaticImage
                  src="../../assets/images/step/step1.png"
                  alt=""
                  placeholder="none"
                  objectFit="contain"
                />
              }
            />
          </div>
          <div className="e-step">
            <StepBox
              stepNumber={2}
              leadText="カレンダーの入室ボタンをクリック"
              imageElement={
                <StaticImage
                  src="../../assets/images/step/step2.png"
                  alt=""
                  placeholder="none"
                  objectFit="contain"
                />
              }
              popText="Zoomで授業スタート！"
            />
          </div>
        </div>
      </div>
      <div className="e-style2">
        <div className="e-sub-head">
          <PageHead headingText="受講端末" headingLevel="h3" />
        </div>
        <div className="e-lead">
          スマホ以外
          <br className="u-hidden-lg" />
          <span className="e-em">ほぼすべての端末</span>が利用できます。
        </div>
        <div className="e-lead-sm">（無料体験はスマホもOK！）</div>
        <div className="e-devices">
          <div className="e-device">
            <DeviceBox headText="利用可能端末" />
          </div>
          <div className="e-device">
            <RentalBox headText="レンタルについて" />
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<StyleSectionPropsClassName>`
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(1rem);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  padding-top: 6.25rem;
  padding-bottom: ${rem(160 / 2)};

  @media ${breakpoints.lg} {
    padding-top: ${rem(200)};
    padding-bottom: ${rem(160 / 2)};
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${innerWidth.lg};
      padding-left: ${rem(innerPadding.lg + innerDummyPadding.lg)};
      padding-right: ${rem(innerPadding.lg + innerDummyPadding.lg)};
    }
  }

  > .e-head {
    text-align: center;

    > .e-head-in {
      display: inline-block;
      position: relative;

      > .e-parallax {
        width: 2.925rem;
        height: 2.813rem;
        position: absolute;
        bottom: calc(100% + 0.419rem);
        left: calc(100% + 0.419rem);
        z-index: -1;

        &.is-in-view {
          animation: fadeInUp 1s ease 0s;
        }

        @media ${breakpoints.lg} {
          width: ${rem(83.2)};
          height: ${rem(80)};
          bottom: calc(100% + ${rem(-20)});
          left: calc(50% + ${rem(266)});
        }

        img {
          width: 100%;
        }
      }
    }
  }

  > .e-style1 {
    > .e-sub-head {
      margin-top: 1.875rem;

      @media ${breakpoints.lg} {
        margin-top: ${rem(40)};
      }
    }

    > .e-lead {
      margin-top: 1.875rem;
      font-size: 1.125rem;
      line-height: 1.83;
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(60)};
        font-size: ${rem(24)};
      }

      > .e-em {
        font-weight: bold;
      }
    }

    > .e-steps {
      margin-top: ${rem(60 / 2)};

      @media ${breakpoints.lg} {
        display: flex;
        width: ${rem(1000)};
        max-width: 100%;
        margin-top: ${rem(60)};
        margin-left: auto;
        margin-right: auto;
      }

      > .e-step {
        @media ${breakpoints.lg} {
          width: calc(50% - ${rem(20)} / 2);
        }

        &:nth-child(n + 2) {
          margin-top: ${rem(60 / 2)};

          @media ${breakpoints.lg} {
            margin-top: 0;
            margin-left: ${rem(20)};
          }
        }
      }
    }
  }

  > .e-style2 {
    margin-top: ${rem(200 / 2)};

    @media ${breakpoints.lg} {
      margin-top: ${rem(200)};
    }

    > .e-sub-head {
    }

    > .e-lead {
      margin-top: 1.875rem;
      font-size: 1.125rem;
      line-height: 1.83;
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(60)};
        font-size: ${rem(24)};
      }

      > .e-em {
        font-weight: bold;
      }
    }

    > .e-lead-sm {
      margin-top: 0.375rem;
      font-size: 0.938rem;
      text-align: center;

      @media ${breakpoints.lg} {
        margin-top: ${rem(10)};
        font-size: ${rem(18)};
      }
    }

    > .e-devices {
      margin-top: 3.156rem;

      @media ${breakpoints.lg} {
        display: flex;
        width: ${rem(1000)};
        margin-top: ${rem(60)};
        margin-left: auto;
        margin-right: auto;
      }

      .e-device {
        @media ${breakpoints.lg} {
          width: calc(50% - ${rem(20)} / 2);
        }

        &:nth-child(n + 2) {
          margin-top: ${rem(160 / 2)};

          @media ${breakpoints.lg} {
            margin-top: 0;
            margin-left: ${rem(20)};
          }
        }
      }
    }
  }
`

export const StyleSection: (props: StyleSectionProps) => JSX.Element = StyledComponent

export default StyleSection
