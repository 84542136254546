import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import Rellax from 'rellax'
import {
  color,
  fontFamily,
  innerWidth,
  rem,
  breakpoints,
} from 'variables/_index'

export interface HeadSectionProps {
  readonly imageElement: JSX.Element
  readonly title: string
  readonly year?: string
  readonly leadElement: JSX.Element
}

type HeadSectionPropsClassName = HeadSectionProps & {
  readonly className: string
}

function Component({
  className,
  imageElement,
  title,
  year,
  leadElement,
}: HeadSectionPropsClassName) {
  const ref = useRef<HTMLDivElement>(null)
  const ref2 = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      new Rellax(ref.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }

    if (ref2.current) {
      new Rellax(ref2.current, {
        speed: 1.5, // -10 ~ 10
        center: false,
      })
    }
  }, [])

  return (
    <div className={className}>
      <div className="e-image-wrap">
        <div className="e-image">{imageElement}</div>
      </div>
      <div className="e-inner">
        <div className="e-head">
          <h1 className="e-title">{title}</h1>
          {year && <div className="e-year">｜{year}｜</div>}
          <div ref={ref} className="e-illustration">
            <StaticImage
              src="../../../assets/images/illustration/illustration5-2.png"
              alt=""
              placeholder="none"
            />
          </div>
          <div ref={ref2} className="e-illustration2">
            <StaticImage
              src="../../../assets/images/illustration/illustration3-2.png"
              alt=""
              placeholder="none"
            />
          </div>
        </div>
      </div>
      <div className="e-lead">{leadElement}</div>
    </div>
  )
}

const StyledComponent = styled(Component)<HeadSectionPropsClassName>`
  .e-image-wrap {
    border-top: solid 1px ${color.main};
    border-bottom: solid 1px ${color.main};
    text-align: center;

    > .e-image {
      width: 17.188rem;
      margin-left: auto;
      margin-right: auto;
      border-left: solid 1px ${color.main};
      border-right: solid 1px ${color.main};

      @media ${breakpoints.lg} {
        width: ${rem(500)};
      }

      img {
      }
    }
  }

  > .e-inner {
    width: ${innerWidth.sm};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoints.lg} {
      width: ${rem(500)};
    }

    > .e-head {
      position: relative;
      padding-bottom: ${rem(130 / 2)};

      > .e-title {
        margin-top: 0.594rem;
        font-family: ${fontFamily.zenKaku};
        font-size: 1.563rem;
        font-weight: 500;
        line-height: 1.44;
        text-align: center;
        color: ${color.main};

        @media ${breakpoints.lg} {
          font-size: ${rem(38)};
        }
      }

      > .e-year {
        font-family: ${fontFamily.zenKaku};
        font-size: ${rem(28 / 2)};
        font-weight: 700;
        text-align: center;
        color: ${color.main};
      }

      > .e-illustration {
        width: 1.969rem;
        height: 1.25rem;
        position: absolute;
        bottom: ${rem(82 / 2)};
        right: ${rem(40 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(52)};
          height: ${rem(32.38)};
        }

        img {
        }
      }

      > .e-illustration2 {
        width: ${rem(82.11 / 2)};
        height: ${rem(130 / 2)};
        position: absolute;
        bottom: 0;
        left: ${rem(40 / 2)};

        @media ${breakpoints.lg} {
          width: ${rem(57.11)};
          height: ${rem(90.08)};
        }

        img {
        }
      }
    }
  }

  > .e-lead {
    margin-top: ${rem(20 / 2)};
    font-size: 1.125rem;
    line-height: 1.83;
    text-align: center;

    @media ${breakpoints.lg} {
      font-size: ${rem(24)};
    }
  }
`

export const HeadSection: (props: HeadSectionProps) => JSX.Element =
  StyledComponent

export default HeadSection
