import React from 'react'
import styled from 'styled-components'
import {
  color,
  fontFamily,
  fixedHeaderHeight,
  innerWidth,
  rem,
  breakpoints,
  innerPadding,
  innerDummyPadding,
} from 'variables/_index'

export interface PointLayoutProps {
  readonly head: {
    numberIcon: JSX.Element
    lead: string
    title: string
  }
  readonly body: {
    image: JSX.Element
    lead: JSX.Element
    box: JSX.Element
  }
}

type PointLayoutPropsClassName = PointLayoutProps & {
  readonly className: string
}

function Component({ className, head, body }: PointLayoutPropsClassName) {
  return (
    <div className={className}>
      <div className="e-head">
        <div className="e-head-number">{head.numberIcon}</div>
        <div className="e-head-lead">{head.lead}</div>
        <h3 className="e-head-title">{head.title}</h3>
      </div>
      <div className="e-body">
        <div className="e-inner">
          <div className="e-image">{body.image}</div>
          <div className="e-content">
            <div className="e-lead">{body.lead}</div>
            <div className="e-box">{body.box}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledComponent = styled(Component)<PointLayoutPropsClassName>`
  > .e-head {
    text-align: center;

    > .e-head-number {
    }

    > .e-head-lead {
      margin-top: ${rem(20 / 2)};
      font-size: ${rem(34 / 2)};
      font-family: ${fontFamily.zenKaku};

      @media ${breakpoints.lg} {
        font-size: ${rem(24)};
      }
    }

    > .e-head-title {
      margin-top: ${rem(10 / 2)};
      font-size: ${rem(46 / 2)};
      font-family: ${fontFamily.zenKaku};
      font-weight: 500;
      color: ${color.main};

      @media ${breakpoints.lg} {
        font-size: ${rem(34)};
      }
    }
  }

  > .e-body {
    margin-top: ${rem(40 / 2)};
    padding-bottom: ${rem(60 / 2)};
    background-color: ${color.mainBackground};

    > .e-inner {
      width: calc(${innerWidth.sm} + ${rem((10 / 2) * 2)});
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;

      @media ${breakpoints.lg} {
        width: ${innerWidth.md};
        padding-left: ${rem(innerPadding.md + innerDummyPadding.md)};
        padding-right: ${rem(innerPadding.md + innerDummyPadding.md)};
      }

      > .e-image {
      }

      > .e-content {
        padding: ${rem(60 / 2)} ${rem(50 / 2)} ${rem(80 / 2)};
        background-color: #fff;

        > .e-lead {
          line-height: 2;
        }

        > .e-box {
          margin-top: ${rem(120 / 2)};
        }
      }
    }
  }
`

export const PointLayout: (props: PointLayoutProps) => JSX.Element =
  StyledComponent

export default PointLayout
